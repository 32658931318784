@keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .myscaleimg-2 {
    animation: scaleAnimation 3s infinite; /* 3 seconds for full cycle, and it repeats infinitely */
  }
  

  @keyframes flipAnimation {
    0% {
      transform: perspective(70vh) rotateY(0deg);
    }
    50% {
      transform: perspective(70vh) rotateY(180deg);
    }
    100% {
      transform: perspective(70vh) rotateY(360deg);
    }
  }
  
  .myscaleimg-3 {
    animation: flipAnimation 5s infinite;
    transform-style: preserve-3d;
    transform-origin: center center; /* Ensures rotation is around the center */
  }
  @keyframes moveAnimation {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);  /* Moves 10 pixels to the right */
    }
    100% {
      transform: translateX(-5px);
    }
  }
  
  .myscaleimg-4 {
    animation: moveAnimation 3s infinite; /* 3 seconds for full cycle, and it repeats infinitely */
  }
  
  
  