

.sidebar-elements{
    color: #9D9DA6;
    cursor: pointer;
}
.sidebar-elements:hover{
    color: #fff;
}

.sidebar-menu-title{
    color: #646477 !important;
}