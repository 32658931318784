@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40,500&display=swap');
:root {
    --primary: #C48741;
    --secondary: #D1B880;
    --third: #f9f5ed;
    --white:#FFFFFF;
    --txt-color-1:#3D4C71;
    --txt-color-2:#8896BA;
    --txt-color-3:#C3CDE6;
    --black:#1E1E2D;
    --gray:#9D9EA2;
    --lightgray2:#e5e7f2;
    --yellow:#FFCE3F;
    --yellow:#FFCE3F;
    --light:#FAFAFA;
    --plat1:#D3EFFD;
    --plat2:#F5FCFF;
    --plat3:#096183;
    --gold1:#FFC107;
    --gold2:#fefbe8;
    --gold3:#efaf03;
    --btn-border-radius: 0.475rem;
    --card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    --card-border-radius: 0.625rem;
    --card-cap-padding-y: 0.5rem;
    --card-cap-padding-x: 1rem;
    --bs-border-color:#F4F4F4;
    --badge-border-radius: 0.325rem;
    --badge-padding-x: 0.4rem;
      --badge-padding-y: 0.225rem;
      --badge-font-size: 12px;
  }
  *{
    font-family: 'DM Sans', sans-serif;
  }

  
  .card-shadow{
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px
  }

  .standard_pack{
    color: #2E2E2E;
    background-color: #F9F9F9;
    padding: var(--badge-padding-y) var(--badge-padding-x);
    border-radius: var(--badge-border-radius);
    text-align: center;
    vertical-align: baseline;
    display: inline-flex;
    align-items: center;
    font-size: var(--badge-font-size);
  }

  .primary_badge{
    color: var(--primary);
    background-color: var(--third);
    padding: var(--badge-padding-y) var(--badge-padding-x);
    border-radius: var(--badge-border-radius);
    text-align: center;
    vertical-align: baseline;
    display: inline-flex;
    align-items: center;
    font-size: var(--badge-font-size);
  }

  .plat_pack{
    color: #009EF7;
    background-color: #F1FAFF;
    padding: var(--badge-padding-y) var(--badge-padding-x);
    border-radius: var(--badge-border-radius);
    text-align: center;
    vertical-align: baseline;
    display: inline-flex;
    align-items: center;
    font-size: var(--badge-font-size);
  }

  .gold_pack{
    color: #FFC700;
    background-color:#FFF8DD;
    padding: var(--badge-padding-y) var(--badge-padding-x);
    border-radius: var(--badge-border-radius);
    text-align: center;
    vertical-align: baseline;
    display: inline-flex;
    align-items: center;
    font-size: var(--badge-font-size);
    
  }

  .btn-radius{
border-radius: var( --btn-border-radius);
  }

  .plat1txt{
    color: var(--plat1);
  }
  .plat2txt{
    color: var(--plat2);
  }
  .plat3txt{
    color: var(--plat3);
  }
  .plat3bg{
    background-color: var(--plat3);
  }
  .plat2bg{
    background-color: var(--plat2);
  }
  .plat1bg{
    background-color: var(--plat1);
  }
  .whitetxt{
    color: var(--white);
  }
  .blacktxt{
    color: var(--black);
  }

  .txt-color-1{
    color: var(--txt-color-1);
  }
  .txt-color-2{
    color: var(--txt-color-2);
  }
  .txt-color-3{
    color: var(--txt-color-3);
  }

 
  .graytxt{
    color: var(--gray);
  }
  .primarytxt{
    color: var(--primary);
  }
  .secondarytxt{
    color: var(--secondary);
  }
  .yellowtxt{
    color: var(--yellow);
  }
  .whitebg{
    background-color: var(--white);
  }
  .lightbg{
    background-color: var(--light);
  }
  .blackbg{
    background-color: var(--black);
  }
  .graybg{
    background-color: var(--gray);
  }
  .primarybg{
    background-color: var(--primary) !important;
  }
  .thirdbg{
    background-color: var(--gold2);
  }
  .secondarybg{
    background-color: var(--secondary);
  }
  .yellowbg{
    background-color: var(--yellow);
  }
  .raduis4{
    border-radius: 4px;
  }
  .raduis2{
    border-radius: 3px;
  }
  .raduis8{
    border-radius: 8px;
  }
  .raduis12{
    border-radius: 12px;
  }
  /* Typography  */
.txt-xxs {
    font-size: 9px;
    text-align: justify;
}
.txt-xs {
    font-size: 12px;
    text-align: justify;
}


.txt-sm {
    font-size: 14px;
    text-align: justify;
}

.txt-md {
    font-size: 16px;
    text-align: justify;
}

.txt-l {
    font-size: 18px;
    text-align: justify;
}
.txt-lg {
    font-size: 22px;
    text-align: justify;
}

.txt-xl {
    font-size: 28px;
    text-align: justify;
}
.txt-xxl {
    font-size: 36px;
    text-align: justify;
}
.txt-xxxl {
    font-size: 48px;
    text-align: justify;
}


.container{
    max-width: 1140px !important;
}

.category-hover{
    height:250px;min-width:100%;
}


.category-hover-content {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(196, 135, 65, 0.8) 50%, rgba(196, 135, 65, 0.8) 100%);
    background-size: 200% 100%;
    cursor: pointer;
    transition: background-position 0.3s ease;
    
}

.half-background {
    background: linear-gradient(to right, var(--white) 30%, var(--light) 30%);
  }

  .half-background-2 {
    background: linear-gradient(to right, var(--light) 70%, var(--white) 30%);
  }
  


.category-hover:hover > .category-hover-content {
    background-position: -100% 0;
}


.phones{
  width:450px ;position:absolute;top:0;left:20%
}

.header-section{
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.3;
  background-color: var(--primary); 
  animation: floatCircle 10s linear infinite;
}


@keyframes floatCircle {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-120vh);
  }
}

.newsletter-input{
  max-width: 110px;
}

@media screen and (max-width: 1500px) {
  .phones{
    left:10%
  }
}
@media screen and (max-width: 1100px) {
  .phones{
    left:5%
  }
  .newsletter-input{
    max-width: 110px;
  }
  .header-section{
    min-height: 100vh;
  }
}

@media screen and (max-width: 960px) {
  .phones{
    left:2%
  }
  .newsletter-input{
    max-width: auto;
  }
  .header-section{
    min-height: 70vh;
  }
}

@media screen and (max-width: 900px) {
  .phones{
    top:50px;
    left:2%;
    width:350px ;
  }
  .menu-toggle{
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .phones{
    top:50px;
    left:30%;
    width:150px ;
  }
    .txt-xxl {
        font-size: 32px;
    }

    .txt-xxxl {
        font-size: 42px;
    }
    .category-hover{
        height: 200px;
    }
    .half-background {
      background: linear-gradient(to right, var(--white)  0%, var(--light) 0%);
    }
    .half-background-2 {
      background: linear-gradient(to right, var(--light) 100%, var(--white) 0%);
    }
    
    .how-bg{
      min-height: 300px;
    }
    .header-section{
      min-height: 60vh;
    }
}

@media screen and (max-width: 480px) {
  .header-section{
    min-height: 80vh;
  }
    .txt-md {
        font-size: 15px;
    }
    
    .txt-l {
        font-size: 17px;
    }

    .txt-lg {
        font-size: 20px;
    }

    .txt-xl {
        font-size: 24px;
    }

    .txt-xxl {
        font-size: 28px;
    }

    .txt-xxxl {
        font-size: 36px;
    }
    .category-hover{
        height: 150px;
    }
}

.primarybtn{
  border-radius: 4px;
  border: 1px solid var(--primary) ;
  min-width: 120px;
  min-height: 35px;
  background: none;
}
.white-outline-btn{
  border: 1px solid var(--white) ;
}
.btn{
  border-radius: 4px;
  min-width: 120px;
  min-height: 35px;
  background: none;
}
.dynamic-btn{
  background: none;
  outline: none;
  border: none;
}



@keyframes scalePhones {
  0% {
    top:0;
  }
  100% {
    top:20px;
  }
}



.phones-animation{
  animation: scalePhones 1s infinite alternate;
  cursor: pointer;
}


@keyframes scaleBackground {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 120%; 
  }
}


.tab-item{
  color: var(--black);

}

.header-tab-item{
  color: var(--white);
  cursor: pointer;
  text-decoration: none;
}
.header-tab-item:hover{
  color: var(--secondary);
}

.tab-item:hover{
  color: var(--secondary);
  cursor: pointer;
  
}
.menu-tab-item{
  color: var(--black);
  cursor: pointer;
}
.menutab-item:hover{
  color: var(--white);
background-color: var(--secondary);
}

.myscaleimg{
  animation: scaleBackground 3s infinite alternate;
  cursor: pointer;
}

.myscaleimg-hover:hover{
  animation: scaleBackground 1s infinite alternate;
  cursor: pointer;
}

.tabs{
  /* border-bottom: 1px solid var(--gray); */
}
.headertabs{
  border-bottom: 1px solid var(--gray);
}

.selected-tabs-item{
  color: var(--secondary);
}
.menu-selected-tabs-item{
  color: var(--white);
  background-color: var(--primary);
}
.menu-selected-tabs-item:hover{
  color: var(--white);
  background-color: var(--primary);
}

.row-scrollable {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%; /* Set the desired width for your row */
  display: flex; /* Make the row a flex container */
  flex-wrap: nowrap; /* Prevent flex items from wrapping */
}

.row-scrollable .col-auto {
  flex: 0 0 auto; /* Ensure flex items do not grow or shrink */
  width: auto; /* Let the width of each column be determined by content */
}
.row-scrollable:not(:first-child):not(:last-child) {
  overflow-x: auto;
}

.dotscompleter-3{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.dotscompleter-2{
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.dotscompleter-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  margin-bottom: 0; 
  padding-bottom: 0; 
  height: auto;
}

  


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}


::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 5px; 
}


::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary); 
}
::-webkit-scrollbar-track {
  background-color: var(--light);
}
.border-radius-top-4{
  border-top-left-radius : 4px;
  border-top-right-radius : 4px;
}
.border-radius-left-4{
  border-top-left-radius : 4px;
  border-bottom-left-radius : 4px;
}
.border-radius-right-4{
  border-top-right-radius : 4px;
  border-bottom-right-radius : 4px;
}

.border-radius-left-20{
  border-top-left-radius : 10px;
  border-bottom-left-radius : 10px;
}

.border-radius-top-right{
  border-top-right-radius : 4px;
}
.border-radius-bottom-right{
  border-bottom-right-radius :4px;
}

.border-radius-right{
  border-top-right-radius : 4px;
  border-bottom-right-radius :4px;
}


.border-radius-bottom-4{
  border-bottom-left-radius : 4px;
  border-bottom-right-radius : 4px;
}


.salary-skills{
  border: 1px solid var(--gray);
}


.primarybg-opacity{
  background-color: rgba(195, 134, 65, 0.8);
}

.secondary-opacity{
  background-color: rgba(252, 195, 135, 0.9);
}

.salary{
  border: 5px solid var(--light);
}

.salary-spons{
  border: 2px solid var(--secondary);
}




@keyframes download-button-animation {
  0% {
    background-position: 0% 0;
    border: 2px solid var(--light);
  }
  100% {
    background-position: 100% 0;
    border: 2px solid  transparent;
  }
}

.download-button {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(196, 135, 65, 0.5) 50%, rgba(196, 135, 65, 0.5) 100%);
  background-size: 200% 100%; 
  animation: download-button-animation 3s linear infinite alternate; 
  cursor: pointer;
}




/* Define a CSS class for the hover effect */
.myscaleimg-hover {
  position: relative;
  overflow: hidden;
}

.myscaleimg-hover::before {
  content: "";
  background: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; 
  transform: scale(1);
  transition: transform 0.3s ease;
}

.myscaleimg-hover:hover::before {
  transform: scale(1.1); 
}


#fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  overflow-y: auto; 
}

.toggle-cols {
  display: flex;
}

@media screen and (max-width: 870px) {
  .toggle-cols {
      display: none;
  }

  #menu-toggle-btn {
      display: block;
  }
}


@keyframes fadeInFromLeft {
  from {
      opacity: 0;
      transform: translateX(-100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.fadeInFromLeft {
  animation: fadeInFromLeft 2s forwards;
}

@keyframes fadeInFromRight {
  from {
      opacity: 0;
      transform: translateX(100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.fadeInFromRight {
  animation: fadeInFromRight 2s forwards;
}

@keyframes fadeInFromTop {
  from {
      opacity: 0;
      transform: translateY(-100%);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.fadeInFromTop {
  animation: fadeInFromTop 2s forwards;
}

@keyframes fadeInFromTop {
  from {
      opacity: 0;
      transform: translateY(-100%);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.fadeInFromTop {
  animation: fadeInFromTop 2s forwards;
}

@keyframes schemaanimation {
  0% {
    transform: rotate(0deg) scale(1);
}
25% {
    transform: rotate(15deg) scale(1.05);
}
50% {
    transform: rotate(-15deg) scale(1.1);
}
75% {
    transform: rotate(15deg) scale(1.05);
}
100% {
    transform: rotate(0deg) scale(1);
}
}

.schemaanimation-img {
  animation: schemaanimation 10s infinite;
  transition-timing-function: ease-in-out;
}
.btn-0{
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.bg-none{
background: none;
}

.a-input{
  padding: calc(0.675rem + 1px) calc(1.4rem + 1px) !important;
  border-radius: var(--btn-border-radius) !important;
  outline: none;
  background: none;
}

.a-btn{
  border: 0;
  padding: calc(0.675rem + 1px) calc(1.4rem + 1px);
  border-radius: var(--btn-border-radius);
  outline: none;
  background: none;
}
.a-btn-small{
  border: 0;
  padding: calc(0.575rem + 1px) calc(0.6rem + 1px);
  border-radius: var(--btn-border-radius);
  outline: none;
  background: none;
}

.a-btn-small.primary  {
  background-color: var(--black);
  color: var(--white);
}
.inputs-container{
  border: 0;
  padding-right:calc(1.2rem + 1px);
  padding-top:  calc(0.475rem + 1px)  ;
  padding-bottom:  calc(0.475rem + 1px)  ;
  border-radius: var(--btn-border-radius);
  outline: none;
  font-size: 14px;
  color: var(--gray);
}
.inputs-container.bs-1{
  border: 0.5px solid var(--light);
}

.a-btn.primary  {
    background-color: var(--black);
    color: var(--white);
}


.a-card-container{
 box-shadow:  var(--card-box-shadow);
 border-radius:   var( --card-border-radius);
 padding: var(--card-cap-padding-y) var( --card-cap-padding-x) ; 
 min-height: 400px;
 width: "auto";
 max-width: 90vw;
}

.table-container {
  overflow-x: auto;
  position: relative;
  clear: both;
  border-collapse: separate !important;
  border-spacing: 0;
  vertical-align: middle !important;
  width: 100%;
}



.table-container>table>tr {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: var(--bs-border-color);
  width: 100%;
}
.table-container>table>tr>th {
  color: var(--gray);
  font-size: 14px;
}


table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 0.8rem;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:auto;
}

.action-button{
  display: flex;
  background-color: var(--black);
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.action-button>svg{
 color: var(--white);
 margin: auto;
 font-size: 13px;
}
th.actions, td.actions {
  white-space: nowrap;
  width: 8%; /* Minimal width according to content */
}
