@media (min-width: 850px) {
    .d-block.d-max-lg-none {
      display: none !important;
    }
  }
  
  @media (max-width: 850px) {
    .d-block.d-max-lg-none {
      display: block !important;
    }
  }
  